<div class="bg-white w-full rounded p-6 flex flex-col xl:flex-row gap-12">
  <a [routerLink]="'/shop/car/' + car!.carMainInfoId" routerLinkActive="active"
    class="w-72 h-72 min-w-[18rem] max-w-[18rem] flex rounded relative car-photo mx-auto xl:mx-0">
    <img [id]="'go-to-car-' + car?.carMainInfoId + 'img'" [attr.data-cy]="'go-to-car-' + car?.carMainInfoId + 'img'"
      class="rounded object-contain cursor-pointer" [src]="photo" alt="">
    <!-- <mat-checkbox [id]="'select-' + car?.carMainInfoId + 'checkbox'"
        [attr.data-cy]="'select-' + car?.carMainInfoId + 'checkbox'" class="!absolute right-4 top-2"
        [(ngModel)]="car!.isSelected"></mat-checkbox> -->
    <div *ngIf="isNew" class="bg-red-100 text-red rounded absolute text-xs left-4 py-1 px-2 top-2 text-center">
      <p>NEW</p>
    </div>
  </a>

  <div class="grid grid-cols-2 gap-6">
    <div [id]="'go-to-car-' + car?.carMainInfoId + 'btn'" [attr.data-cy]="'go-to-car-' + car?.carMainInfoId + 'btn'"
      class="col-span-2 font-bold text-xl">
      <a [routerLink]="'/shop/car/' + car!.carMainInfoId" routerLinkActive="active">{{car?.make}} {{car?.model}}</a>
    </div>

    <div class="col-span-2 xl:col-span-1 flex flex-col gap-6">
      <div *ngIf="car && car.subHeader" class="px-3 py-2 rounded border bg-[#F2E8FF] border-white-500 w-fit">
        <p>{{car.subHeader | ellipsis:30}}</p>
      </div>

      <div class="flex flex-col text-sm gap-2">
        <p><span class="text-white-300">Variant:</span> {{car?.variant}}</p>
        <p *ngIf="car && car.trim"><span class="text-white-300">Trim:</span> {{car.trim}}</p>
        <p *ngIf="car && car.vin"><span class="text-white-300">VIN:</span> {{car.vin}}</p>
      </div>

      <div class="flex flex-wrap gap-3">
        <div class="bg-white-100 border border-white-500 rounded py-2 px-3">
          {{car?.manufactureYear}}
        </div>
        <div class="bg-white-100 border border-white-500 rounded py-2 px-3">
          {{car?.mileage | number}}km
        </div>
        <div class="bg-white-100 border border-white-500 rounded py-2 px-3">
          {{car?.fuelType}}
        </div>
        <div class="bg-white-100 border border-white-500 rounded py-2 px-3">
          {{car?.gearbox}}
        </div>
        <div *ngIf="car && car.color" class="bg-white-100 border border-white-500 rounded py-2 px-3">
          {{car.color}}
        </div>
      </div>

      <div class="flex gap-72 xl:gap-6 xl:flex-col">
        <div class="flex flex-col gap-3">
          <div class="flex items-baseline gap-2">
            <p class="font-bold text-red text-2xl">€{{car?.sellingPrice | number}}</p>
            <p class="text-sm text-white-600">{{car?.vatStatus ? vatStatus.ExVAT : vatStatus.InclVAT}}</p>
          </div>

          <div class="flex gap-2 items-center text-sm"
            *ngIf="car!.available && car!.available.from && !car!.available.now">
            <fa-icon [icon]="['fas','square-arrow-up-right']"></fa-icon>
            <p>Available from:</p>
            <p>{{availableFrom | date : 'dd/MM/yyyy'}}</p>
          </div>

          <div class="flex gap-2 items-center text-sm"
            *ngIf="car!.available && car!.available.to && car!.available.now">
            <fa-icon [icon]="['fas','square-arrow-down-left']"></fa-icon>
            <p>Available to:</p>
            <p>{{availableTo | date : 'dd/MM/yyyy'}}</p>
          </div>
        </div>

        <div class="flex gap-6">
          <!-- <primary-btn (clickEmitter)="addToCart()" [id]="'add-to-buy-' + car?.carMainInfoId" class="w-1/2"
            [blueBtn]="true" btnText="Add to buy list"></primary-btn> -->

          <div *ngIf="car!.available && car!.available.now" class="flex gap-1 items-center text-sm">
            <fa-icon [icon]="['fas','circle-check']" class="text-green-100"></fa-icon>

            <p>Available now</p>
          </div>
        </div>
      </div>

    </div>

    <div class="col-span-2 xl:col-span-1 2xl:pl-12 2xl:border-l border-white-500">
      <div class="font-bold" *ngIf="bc">
        <p>Business case for {{bcCountry}}:</p>
      </div>

      <div class="grid grid-cols-2 gap-5 mt-7 mb-16" *ngIf="bc">
        <div class="col-span-1 flex gap-3">
          <fa-icon [icon]="['fal','check']"></fa-icon>
          <p>Retail Ex VAT: €{{bc.spotPrice ? (bc!.spotPrice | number) : 'None'}}</p>
        </div>

        <div class="col-span-1 flex gap-3">
          <fa-icon [icon]="['fal','check']"></fa-icon>
          <p>Retail Ex VAT Ex TAX: €{{bc.spotPriceExTax ? (bc!.spotPriceExTax | number) : 'None'}}</p>
        </div>

        <div class="col-span-1 flex gap-3">
          <fa-icon [icon]="['fal','check']"></fa-icon>
          <p>Monthly sold: {{bc.carsSold30Days}}</p>
        </div>

        <div class="col-span-1 flex gap-3">
          <fa-icon [icon]="['fal','check']"></fa-icon>
          <p>Sales factor: {{bc.salesFactor && isNotNumber(bc.salesFactor) ? 'None' : (bc.salesFactor | number)}}</p>
        </div>

        <div class="col-span-1 flex gap-3">
          <fa-icon [icon]="['fal','check']"></fa-icon>
          <p>Spot level: {{bc.spotLevel}}</p>
        </div>

        <div class="col-span-1 flex gap-3 text-green-200"
          [ngClass]="{'!text-red' : (bc.spotPrice - car!.sellingPrice!  < 0)}">
          <fa-icon [icon]="['fal','check']"></fa-icon>
          <p class="font-bold">Est Profit: €{{bc.spotPrice - car!.sellingPrice! | number}}</p>
        </div>
      </div>

      <div class="flex flex-wrap gap-6">
        <div class="flex gap-3">
          <fa-icon [icon]="['fal','cube']"></fa-icon>
          <p>Location: <span class="font-bold">{{car?.locationName}}</span></p>
        </div>

        <div class="flex gap-3">
          <fa-icon [icon]="['fal','share-nodes']"></fa-icon>
          <p>Source: <span class="text-blue-500">{{car?.sourceName}}</span></p>
        </div>

        <div class="flex gap-3">
          <p>Status: {{car?.carStatus}} </p>
        </div>
      </div>
    </div>


    <div class="col-span-2  text-sm text-white-300">
      <p>Date added: {{car!.createdAt | date : 'dd/MM/yyyy' }}</p>
    </div>
  </div>
</div>
