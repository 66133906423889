import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, map, Observable, startWith } from 'rxjs';
import { SignInLog } from 'src/app/core/models/log.model';
import { UserShortInfo } from 'src/app/core/models/user.model';
import { SettingsService } from 'src/app/core/services/settings.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-sign-in-logs',
  templateUrl: './sign-in-logs.component.html',
  styleUrls: ['./sign-in-logs.component.scss']
})

export class SignInLogsComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  users: UserShortInfo[] = [];
  usersDropdown: DropdownOption[] = [];

  userControl = new FormControl('');
  dateFromControl = new FormControl('');
  dateToControl = new FormControl('');

  filteredOptions = new Observable<UserShortInfo[]>;

  headers: TableHeaderMap[] = [
    {
      value: 'fullName',
      tableView: 'user display name'
    },
    {
      value: 'id',
      tableView: 'user id'
    },
    {
      value: 'email',
      tableView: 'user email'
    },
    {
      value: 'signInDate',
      tableView: 'sign in date'
    },
    {
      value: 'signInTime',
      tableView: 'sign in time'
    },
    {
      value: 'application',
      tableView: 'application'
    },
    {
      value: 'callerIpAddress',
      tableView: 'caller ip address'
    },
    {
      value: 'deviceDetails',
      tableView: 'device details'
    },
    {
      value: 'location',
      tableView: 'location'
    },
    {
      value: 'logInDetails',
      tableView: 'Log in details'
    },
  ];

  displayedColumns = this.headers.map(h => h.value);

  logs: SignInLog[] = [];

  @ViewChild('logsTable') logsTable: AppTableComponent | undefined;

  constructor(private settingsService: SettingsService,
    private utilsService: UtilsService,
    private snackbar: SnackbarService
  ) { }

  ngOnInit() {
    this.settingsService.getAllUsers().subscribe(resp => {
      this.users = resp;

      this.filteredOptions = this.userControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );

      this.loading.next(false);
    });
  }

  resetFilters() {

  }

  applyFilters() {
    let body: any = {
      page: 0,
      itemsPerPage: 20
    };

    if (this.dateFromControl.value) {
      body.dateFrom = this.utilsService.formatDateValue(this.dateFromControl.value);
    }

    if (this.dateToControl.value) {
      body.dateTo = this.utilsService.formatDateValue(this.dateToControl.value);
    }

    if (this.userControl.value) {
      body.usernames = [this.userControl.value];
    }

    this.loading.next(true);

    this.settingsService.getUserSignInLogs(body).subscribe({
      next: resp => {
        this.loading.next(false);

        this.logsTable?.updateTable(resp);
      },
      error: err => {
        this.loading.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  private _filter(value: string): UserShortInfo[] {
    const filterValue = value.toLowerCase();

    return this.users.filter(option => option.email.toLowerCase().includes(filterValue));
  }
}
