<div class="overflow-scroll max-h-[80vh] scroll-x overflow-x-hidden flex flex-col gap-9 px-6">
  <div class="flex justify-between items-center">
    <p class="text-2xl">{{car.carConfig.make}} {{car.carConfig.model}}</p>

    <fa-icon id="close-modal-btn" data-cy="close-modal-btn" class="cursor-pointer" [icon]="['far','xmark']"
      (click)="close()"></fa-icon>
  </div>

  <div class="twoCols">
    <p class="col-span-2 pb-3 border-b border-white-500 font-bold">Car registration tracking</p>

    <div>
      <p>Car registered at C2C</p>
    </div>

    <ng-container
      *ngTemplateOutlet="hasNoEvent('carRegC2C') ? hasNoEventTemplate : hasEvent; context: { $implicit: {name:'carRegC2C',control:form.controls.carRegisteredC2C}}">
    </ng-container>

    <div>
      <p>Car deregistered at C2C</p>
    </div>

    <ng-container
      *ngTemplateOutlet="hasNoEvent('carDeRegC2C') ? hasNoEventTemplate : hasEvent; context: { $implicit: {name:'carDeRegC2C',control:form.controls.carDeRegisteredC2C}}">
    </ng-container>

    <p class="col-span-2 pb-3 border-b border-white-500 font-bold">DHL tracking</p>

    <div>
      <p>DHL requested</p>
    </div>

    <ng-container
      *ngTemplateOutlet="hasNoEvent('dhlRequested') ? hasNoEventTemplate : hasEvent; context: { $implicit: {name:'dhlRequested',control:form.controls.dhlRequested}}">
    </ng-container>

    <div>
      <p>DHL tracking no</p>
    </div>

    <div>
      <app-input id="dhl-tracking-no" [control]="form.controls.dhlTrackingNo"
        placeholder="Insert DHL tracking no"></app-input>
    </div>

    <!-- <div class="col-span-2 w-fit justify-self-end text-blue-500">
      <p>Track your order</p>
    </div> -->

    <!-- ----------- COC ----------- -->
    <p class="col-span-2 pb-3 border-b border-white-500 font-bold">COC tracking</p>

    <p>COC status</p>

    <app-read-only-input>{{hasNoEvent('cocDetailsSentAt') ?
      (hasNoEvent('cocDetailsReceived') ? 'COC not received' : 'COC received') : 'COC sent'}}</app-read-only-input>

    <div>
      <p>COC received</p>
    </div>

    <ng-container
      *ngTemplateOutlet="hasNoEvent('cocDetailsReceived') ? hasNoEventTemplate : hasEvent; context: { $implicit: {name:'cocDetailsReceived',control:form.controls.cocReceivedAt}}">
    </ng-container>

    <div>
      <p>COC sent</p>
    </div>
    <ng-container
      *ngTemplateOutlet="hasNoEvent('cocDetailsSentAt') ? hasNoEventTemplate : hasEvent; context: { $implicit: {name:'cocDetailsSentAt',control:form.controls.cocSentAt}}">
    </ng-container>

    <!-- ----------- registration ----------- -->
    <p class="col-span-2 pb-3 border-b border-white-500 font-bold">Registration tracking</p>

    <p>Registration status</p>

    <app-read-only-input>{{hasNoEvent('registrationDocumentsSent') ?
      (hasNoEvent('registrationDocumentsReceived') ? 'Registration docs not received' : 'Registration docs received') :
      'Registration docs sent'}}</app-read-only-input>

    <div>
      <p>Registration documents received</p>
    </div>

    <ng-container
      *ngTemplateOutlet="hasNoEvent('registrationDocumentsReceived') ? hasNoEventTemplate : hasEvent; context: { $implicit: {name:'registrationDocumentsReceived',control:form.controls.registrationDocumentsReceived}}">
    </ng-container>

    <div>
      <p>Registration documents sent</p>
    </div>

    <ng-container
      *ngTemplateOutlet="hasNoEvent('registrationDocumentsSent') ? hasNoEventTemplate : hasEvent; context: { $implicit: {name:'registrationDocumentsSent',control:form.controls.registrationDocumentsSent}}">
    </ng-container>

    <!-- ----------- CC ----------- -->
    <p class="col-span-2 pb-3 border-b border-white-500 font-bold">CC tracking</p>

    <p>CC status</p>

    <app-read-only-input>{{hasNoEvent('ccDocumentsSent') ?
      (hasNoEvent('ccDocumentsReceivedAt') ? 'CC not received' : 'CC received') : 'CC sent'}}</app-read-only-input>

    <div>
      <p>CC received (France only)</p>
    </div>

    <ng-container
      *ngTemplateOutlet="hasNoEvent('ccDocumentsReceivedAt') ? hasNoEventTemplate : hasEvent; context: { $implicit: {name:'ccDocumentsReceivedAt',control:form.controls.ccDocumentsReceivedAt}}">
    </ng-container>

    <div>
      <p>CC sent (France only)</p>
    </div>

    <ng-container
      *ngTemplateOutlet="hasNoEvent('ccDocumentsSent') ? hasNoEventTemplate : hasEvent; context: { $implicit: {name:'ccDocumentsSent',control:form.controls.ccDocumentsSent}}">
    </ng-container>
  </div>

  <div class="twoCols">
    <p class="col-span-2 pb-3 border-b border-white-500 font-bold">Car documents</p>

    <input id="uploadInput" class="hidden" #selectFile type="file" accept=".pdf" [formControl]="uploadFileControl"
      (change)="uploadFile($event)">

    <p>Invoice</p>

    <div (click)="openUpload(docTypes.INVOICE)" *ngIf="!finishedOrder"
      class="cursor-pointer active:scale-95 border border-black px-3 py-1 flex items-center gap-4 rounded justify-self-end">
      <fa-icon [icon]="['fal','up-to-line']"></fa-icon>

      <p>Upload file</p>
    </div>

    <div class="col-span-2 flex flex-wrap gap-6" *ngIf="car.documentsDetails.documents.invoicing.documents.length > 0">
      <div *ngFor="let d of car.documentsDetails.documents.invoicing.documents"
        class="bg-white-700 rounded px-9 py-3 flex gap-4 w-fit items-center">
        <p class="whitespace-nowrap overflow-hidden text-ellipsis max-w-sm">{{d.name}}</p>

        <p class="underline text-blue-500 cursor-pointer active:scale-95 text-sm"
          (click)="downloadCarDocument(d.id,'INV')">
          download doc
        </p>

        <fa-icon class="cursor-pointer" [icon]="['far','xmark']"
          (click)="removeOrderDocument(d.id,docTypes.INVOICE)"></fa-icon>
      </div>
    </div>


    <p>Registration document</p>

    <div (click)="openUpload(docTypes.REGISTRATION)" *ngIf="!finishedOrder"
      class="cursor-pointer active:scale-95 border border-black px-3 py-1 flex items-center gap-4 rounded justify-self-end">
      <fa-icon [icon]="['fal','up-to-line']"></fa-icon>

      <p>Upload file</p>
    </div>

    <div class="col-span-2 flex flex-wrap gap-6"
      *ngIf="car.documentsDetails.documents.registration.documents.length > 0">
      <div *ngFor="let d of car.documentsDetails.documents.registration.documents"
        class="bg-white-700 rounded px-9 py-3 flex gap-4 w-fit items-center">
        <p class="whitespace-nowrap overflow-hidden text-ellipsis max-w-sm">{{d.name}}</p>

        <p class="underline text-blue-500 cursor-pointer active:scale-95 text-sm"
          (click)="downloadCarDocument(d.id,'REG')">download doc
        </p>

        <fa-icon class="cursor-pointer" [icon]="['far','xmark']"
          (click)="removeOrderDocument(d.id,docTypes.REGISTRATION)"></fa-icon>
      </div>
    </div>

    <p>COC</p>

    <div (click)="openUpload(docTypes.COC)" *ngIf="!finishedOrder"
      class="cursor-pointer active:scale-95 border border-black px-3 py-1 flex items-center gap-4 rounded justify-self-end">
      <fa-icon [icon]="['fal','up-to-line']"></fa-icon>

      <p>Upload file</p>
    </div>

    <div class="col-span-2 flex flex-wrap gap-6" *ngIf="car.documentsDetails.documents.coc.documents.length > 0">
      <div *ngFor="let d of car.documentsDetails.documents.coc.documents"
        class="bg-white-700 rounded px-9 py-3 flex gap-4 w-fit items-center">
        <p class="whitespace-nowrap overflow-hidden text-ellipsis max-w-sm">{{d.name}}</p>

        <p class="underline text-blue-500 cursor-pointer active:scale-95 text-sm"
          (click)="downloadCarDocument(d.id,'COC')">
          download doc
        </p>

        <fa-icon class="cursor-pointer" [icon]="['far','xmark']"
          (click)="removeOrderDocument(d.id,docTypes.COC)"></fa-icon>
      </div>
    </div>


    <p>CC</p>

    <div class="flex justify-end">
      <div (click)="openUpload(docTypes.CC)" *ngIf="!finishedOrder"
        class="cursor-pointer active:scale-95 border border-black px-3 py-1 flex items-center gap-4 rounded">
        <fa-icon [icon]="['fal','up-to-line']"></fa-icon>

        <p>Upload file</p>
      </div>
    </div>

    <div class="col-span-2 flex flex-wrap gap-6" *ngIf="car.documentsDetails.documents.cc.documents.length > 0">
      <div *ngFor="let d of car.documentsDetails.documents.cc.documents"
        class="bg-white-700 rounded px-9 py-3 flex gap-4 w-fit items-center">
        <p class="whitespace-nowrap overflow-hidden text-ellipsis max-w-sm">{{d.name}}</p>

        <p class="underline text-blue-500 cursor-pointer active:scale-95 text-sm"
          (click)="downloadCarDocument(d.id,'CC')">
          download doc
        </p>

        <fa-icon class="cursor-pointer" [icon]="['far','xmark']"
          (click)="removeOrderDocument(d.id,docTypes.CC)"></fa-icon>
      </div>
    </div>
  </div>

  <div class="flex justify-end" *ngIf="!finishedOrder">
    <primary-btn id="update-documents-settings" [blueBtn]="true" (clickEmitter)="update()">Update</primary-btn>
  </div>
</div>

<ng-template #hasEvent let-event>
  <div class="flex gap-6 justify-between">
    <app-datepicker [id]="event.name" class="w-1/2 2xl:w-3/5" [control]="event.control"
      placeholder="dd/MM/yyyy"></app-datepicker>

    <secondary-btn [id]="'cancel-event-' + event.name" class="w-1/2 2xl:w-1/3"
      (clickEmitter)="toggleOff(event.name); resetControl(event.control)">Cancel
      event</secondary-btn>
  </div>
</ng-template>

<ng-template #hasNoEventTemplate let-event>
  <div class="w-fit justify-self-end">
    <secondary-btn [id]="'create-event-' + event.name" (clickEmitter)="toggleOn(event.name)">Create
      event</secondary-btn>
  </div>
</ng-template>
