import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderCar, RemoveOrderDocumentBody, UploadCarDocumentBody } from 'src/app/core/models/order.model';
import { CoreService } from 'src/app/core/services/core.service';
import { OrdersService } from 'src/app/core/services/orders.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { CarDocumentsType } from '../../order-process-car/order-process-car-documents/order-process-car-documents.component';
import moment from 'moment';

export interface OrderCarDocumentsModalComponentData {
  car: OrderCar,
  orderId: string,
  finishedOrder: boolean
}

@Component({
  selector: 'app-order-car-documents-modal',
  templateUrl: './order-car-documents-modal.component.html',
  styleUrls: ['./order-car-documents-modal.component.scss']
})
export class OrderCarDocumentsModalComponent implements OnInit {
  docTypes = CarDocumentsType;
  uploadFileType = '';
  uploadFileControl = new FormControl();

  finishedOrder = this.data.finishedOrder;

  car: OrderCar = JSON.parse(JSON.stringify(this.data.car));

  countries = this.coreService.countriesDropdown;

  documentsDeliveryForm = this.fb.group({
    country: [this.car.documentsDetails.country.id],
    city: [this.car.documentsDetails.city],
    address: [this.car.documentsDetails.address],
    zipcode: [this.car.documentsDetails.postalCode],
    firstName: [this.car.documentsDetails.contactDetails?.firstName],
    lastName: [this.car.documentsDetails.contactDetails?.lastName],
    email: [this.car.documentsDetails.contactDetails?.email],
    phone: [this.car.documentsDetails.contactDetails?.phone],
  });

  form = this.fb.group({
    carRegisteredC2C: [this.car.carRegisteredC2C],
    carDeRegisteredC2C: [this.car.carDeRegisteredC2C],
    dhlRequested: [this.car.dhlRequested],
    dhlTrackingNo: [this.car.dhlTrackingNo],
    registrationDocumentsReceived: [this.car.documentsDetails.documents.registration.receivedAt],
    registrationDocumentsSent: [this.car.documentsDetails.documents.registration.sentAt],
    cocReceivedAt: [this.car.documentsDetails.documents.coc.receivedAt],
    cocSentAt: [this.car.documentsDetails.documents.coc.sentAt],
    ccDocumentsReceivedAt: [this.car.documentsDetails.documents.cc.receivedAt],
    ccDocumentsSent: [this.car.documentsDetails.documents.cc.sentAt],
  })

  events = [
    {
      event: 'carRegC2C',
      value: this.car.carRegisteredC2C ? true : false
    },
    {
      event: 'carDeRegC2C',
      value: this.car.carDeRegisteredC2C ? true : false
    },
    {
      event: 'dhlRequested',
      value: this.car.dhlRequested ? true : false
    },
    {
      event: 'registrationDocumentsReceived',
      value: this.car.documentsDetails.documents.registration.receivedAt ? true : false
    },
    {
      event: 'registrationDocumentsSent',
      value: this.car.documentsDetails.documents.registration.sentAt ? true : false
    },
    {
      event: 'cocDetailsReceived',
      value: this.car.documentsDetails.documents.coc.receivedAt ? true : false
    },
    {
      event: 'cocDetailsSentAt',
      value: this.car.documentsDetails.documents.coc.sentAt ? true : false
    },
    {
      event: 'ccDocumentsReceivedAt',
      value: this.car.documentsDetails.documents.cc.receivedAt ? true : false
    },
    {
      event: 'ccDocumentsSent',
      value: this.car.documentsDetails.documents.cc.sentAt ? true : false
    }
  ];

  constructor(public dialogRef: MatDialogRef<OrderCarDocumentsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderCarDocumentsModalComponentData,
    private coreService: CoreService,
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private ordersService: OrdersService,
    private spinner: SpinnerHandlerService,
    private snackbar: SnackbarService) { }

  ngOnInit(): void {
    if (this.finishedOrder) {
      this.form.disable();
      this.documentsDeliveryForm.disable();
    }
  }

  close() {
    let documents = {
      registrationDocuments: this.car.documentsDetails.documents.registration.documents,
      ccDocuments: this.car.documentsDetails.documents.cc.documents,
      cocDocuments: this.car.documentsDetails.documents.coc.documents,
      invoicingDocuments: this.car.documentsDetails.documents.invoicing.documents,
    }

    this.dialogRef.close({ documents: documents });
  }

  update() {
    let documentsDetails = {
      country: this.documentsDeliveryForm.controls.country.value,
      city: this.documentsDeliveryForm.controls.city.value!,
      address: this.documentsDeliveryForm.controls.address.value!,
      postalCode: this.documentsDeliveryForm.controls.zipcode.value!,
      contactDetails: {
        email: this.documentsDeliveryForm.controls.email.value!,
        phone: this.documentsDeliveryForm.controls.phone.value!,
        firstName: this.documentsDeliveryForm.controls.firstName.value!,
        lastName: this.documentsDeliveryForm.controls.lastName.value!,
      },
      registration: {
        receivedAt: this.getValue(this.form.controls.registrationDocumentsReceived),
        sentAt: this.getValue(this.form.controls.registrationDocumentsSent),
      },
      cc: {
        receivedAt: this.getValue(this.form.controls.ccDocumentsReceivedAt),
        sentAt: this.getValue(this.form.controls.ccDocumentsSent),
      },
      coc: {
        receivedAt: this.getValue(this.form.controls.cocReceivedAt),
        sentAt: this.getValue(this.form.controls.cocSentAt),
      },
      carRegisteredC2C: this.getValue(this.form.controls.carRegisteredC2C),
      carDeRegisteredC2C: this.getValue(this.form.controls.carDeRegisteredC2C),
      dhlRequested: this.getValue(this.form.controls.dhlRequested),
      dhlTrackingNo: this.form.controls.dhlTrackingNo.value!,
    };

    let documents = {
      registrationDocuments: this.car.documentsDetails.documents.registration.documents,
      ccDocuments: this.car.documentsDetails.documents.cc.documents,
      cocDocuments: this.car.documentsDetails.documents.coc.documents,
      invoicingDocuments: this.car.documentsDetails.documents.invoicing.documents,
    }

    this.dialogRef.close({ documentsDetails: documentsDetails, documents: documents });
  }

  toggleOn(event: string) {
    this.events.find(e => e.event === event)!.value = true;
  }

  toggleOff(event: string) {
    this.events.find(e => e.event === event)!.value = false;
  }

  hasNoEvent(event: string): boolean {
    return !this.events.find(e => e.event === event)!.value;
  }

  resetControl(control: any) {
    control.reset();
  }

  getValue(control: FormControl) {
    if (control.value) {
      return typeof (control.value) === 'string' ? moment(control.value).format("yyyy-MM-DD") : control.value.format("yyyy-MM-DD");
    } else {
      return "";
    }
  }

  openUpload(type: CarDocumentsType) {
    this.uploadFileType = type;

    document.getElementById('uploadInput')?.click();
  }

  uploadFile(event: Event) {
    const target = event.target as HTMLInputElement;

    let file = target.files![0];

    this.utilsService.convertToBase64(file).then(fileBase64 => {
      let body: UploadCarDocumentBody = {
        orderId: this.data.orderId,
        carMainInfoId: this.car.carMainInfoId!,
        sellerId: this.car.carConfig.seller,
        doc: {
          name: file.name,
          data: fileBase64,
          type: this.uploadFileType,
        }
      };

      this.spinner.showProgressBar.next(true);

      this.ordersService.uploadCarDocument(body).subscribe({
        next: resp => {
          let carResp = resp.find(c => c.carMainInfoId === this.car.carMainInfoId)!;

          this.car.documentsDetails.documents.cc = carResp.documentsDetails.cc;
          this.car.documentsDetails.documents.registration = carResp.documentsDetails.registration;
          this.car.documentsDetails.documents.coc = carResp.documentsDetails.coc;
          this.car.documentsDetails.documents.invoicing = carResp.documentsDetails.invoicing;

          this.spinner.showProgressBar.next(false);

          this.snackbar.positiveSentiment('File uploaded');
        },
        error: err => {
          this.spinner.showProgressBar.next(false);

          this.snackbar.negativeSentiment(err.error);
        }
      });
    });
  }

  downloadCarDocument(docId: string, docType: string) {
    this.spinner.showProgressBar.next(true);

    let body = {
      orderId: this.data.orderId!,
      id: docId,
      docType: docType,
      carMainInfoId: this.data.car.carMainInfoId
    };

    this.ordersService.downloadOrderCarDocument(body).subscribe({
      next: resp => {
        window.open(resp, "_blank");

        this.spinner.showProgressBar.next(false);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinner.showProgressBar.next(false);
      }
    })
  }

  removeOrderDocument(docId: string, docType: string) {
    let body: RemoveOrderDocumentBody = {
      orderId: this.data.orderId,
      id: docId,
      filter: {
        carFilter: {
          sellerId: this.car.carConfig.seller,
          carMainInfoId: this.car.carMainInfoId,
          docType: docType
        }
      }
    };

    this.spinner.showProgressBar.next(true);

    this.ordersService.removeOrderDocument(body).subscribe({
      next: resp => {
        switch (docType) {
          case CarDocumentsType.INVOICE:
            this.car.documentsDetails.documents.invoicing.documents = resp;
            break;
          case CarDocumentsType.REGISTRATION:
            this.car.documentsDetails.documents.registration.documents = resp;
            break;
          case CarDocumentsType.COC:
            this.car.documentsDetails.documents.coc.documents = resp;
            break;
          case CarDocumentsType.CC:
            this.car.documentsDetails.documents.cc.documents = resp;
            break;
        }

        this.spinner.showProgressBar.next(false);

        this.snackbar.positiveSentiment('File removed');
      },
      error: err => {
        this.spinner.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    });
  }
}
