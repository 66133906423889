<div class="flex flex-col gap-9">
  <div class="flex flex-row gap-6 items-center">
    <div class="dropdown relative">
      <p class="absolute z-10 text-white-300 text-xs -top-5">Search user</p>

      <input class="search-user" type="text" placeholder="Pick one" aria-label="Number" matInput
        [formControl]="userControl" [matAutocomplete]="auto">

      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.email">{{option.email}}</mat-option>
      </mat-autocomplete>
    </div>

    <app-datepicker label="Date from" [control]="dateFromControl"></app-datepicker>

    <app-datepicker label="Date to" [control]="dateToControl"></app-datepicker>

    <secondary-btn id="reset-filters" btnText="Reset filters" (clickEmitter)="resetFilters()"></secondary-btn>

    <primary-btn id="filter-table" btnText="Apply" (clickEmitter)="applyFilters()"></primary-btn>
  </div>

  <div>
    <app-table #logsTable [data]="logs" [headersMap]="headers" [displayedColumns]="displayedColumns"
      [hidePaginator]="false"></app-table>
  </div>
</div>

<ng-template #placeholder>
  <div class="w-full flex flex-col gap-6">
    <div class="w-full h-9 placeholder-animation"></div>

    <app-table-placeholder></app-table-placeholder>
  </div>
</ng-template>
