import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CreateTransportBody, IDocument, OrderCar, OrderTransport, UpdateCarTransportCostBody, UpdateTransportDetailsBody, UploadTransportDocumentBody } from 'src/app/core/models/order.model';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { CreateTransportModalComponent } from './create-transport-modal/create-transport-modal.component';
import { OrdersService } from 'src/app/core/services/orders.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { AddCarToTransportModalComponent } from './add-car-to-transport-modal/add-car-to-transport-modal.component';
import { UtilsService } from 'src/app/core/services/utils.service';
import { OrderTransportStatus } from 'src/app/core/models/info.model';
import { OrderStatus } from '../../orders-view/orders-view.component';

interface TransportLocation {
  cars: TransportCar[],
  locationDetails: {
    locationId: string,
    location: string,
    city: string,
    address: string,
    postalCode: string,
  }
}

interface TransportCar {
  make: string
  model: string,
  regNo: string,
  carMainInfoId: string,
  cost: number
}

enum TransportDocumentsType {
  CMR = 'CMR',
  Transport = 'INV'
}

@Component({
  selector: 'app-order-process-transport',
  templateUrl: './order-process-transport.component.html',
  styleUrls: ['./order-process-transport.component.scss']
})
export class OrderProcessTransportComponent implements OnInit {
  headers: TableHeaderMap[] = [
    {
      value: 'carConfig.sellerName',
      tableView: 'seller'
    },
    {
      value: 'carPricing.invoiceSellingPrice',
      tableView: 'price'
    },
    {
      value: 'vatString',
      tableView: 'vat type'
    },
    {
      value: 'carConfig.regNo',
      tableView: 'Registration number'
    },
    {
      value: 'carConfig.make',
      tableView: 'make'
    },
    {
      value: 'carConfig.model',
      tableView: 'model'
    },
    {
      value: 'carConfig.vin',
      tableView: 'vin'
    },
    {
      value: 'carConfig.mileage',
      tableView: 'mileage'
    },
    {
      value: 'carConfig.firstReg',
      tableView: 'first registration'
    },
    {
      value: 'carConfig.color',
      tableView: 'color'
    },
  ]

  displayedColumns = ['selectAll'].concat(this.headers.map(h => h.value));

  orderId = this.orderFlowSyncService.orderId;

  buyerCountry = this.orderFlowSyncService.order!.buyer.country.location;

  carsNotInTransport: OrderCar[] = [];

  carsInTransport: OrderCar[] = [];

  uploadControl = new FormControl();
  excelFileName: string | undefined;

  transports: OrderTransport[] = this.orderFlowSyncService.order!.transportDetails.transports;
  transportDropdown: DropdownOption[] = [];

  transportControl = new FormControl();
  orderTransportDetails = this.orderFlowSyncService.order!.transportDetails;

  transportDetailsForm = this.fb.group({
    companyName: ['', Validators.required],
    truckReg: [''],
    load: [''],
    status: [''],
    pickupDate: [''],
    estimatedDeliveryDate: [''],
    actualDeliveryDate: [''],
    invoiceSentAt: [''],
    CMRSentAt: [''],
  });

  transportStatuses: DropdownOption[] = this.orderFlowSyncService.transportStatus;
  transportStatusEnum = OrderTransportStatus;

  selectedTransport: OrderTransport | undefined;

  pickupLocations: TransportLocation[] = [];
  deliveryLocations: TransportLocation[] = [];

  transportInvoiceSent = false;
  cmrSent = false;

  transporterPaidSent = false;
  transporterPaidSentControl = new FormControl<any>({ value: '', disabled: true }, Validators.required);

  transportationInvoice = "";
  cmr = "";

  docTypes = TransportDocumentsType;
  uploadFileType = '';
  uploadFileControl = new FormControl();

  invoicingDocuments: IDocument[] = [];
  cmrDocuments: IDocument[] = [];

  orderStatus = this.orderFlowSyncService.orderStatus;
  orderStatuses = OrderStatus;

  @ViewChild('carsNotInTransportTable') carsNotInTransportTable: AppTableComponent | undefined;
  @ViewChild('carsInTransportTable') carsInTransportTable: AppTableComponent | undefined;

  constructor(private orderFlowSyncService: OrdersFlowSyncService,
    private dialog: MatDialog,
    private orderService: OrdersService,
    private spinner: SpinnerHandlerService,
    private snackbar: SnackbarService,
    private utilsService: UtilsService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.carsNotInTransport = this.orderFlowSyncService.carList.filter(c => !this.transports.some(t => t.cars.find(tc => tc.carMainInfoId === c.carMainInfoId)));

    this.transportDropdown = this.orderFlowSyncService.order!.transportDetails.transports.map(t => ({ viewValue: t.name, value: t.transportId }));

    if (this.orderStatus === OrderStatus.FINISHED) this.transportDetailsForm.disable();
  }

  openAddTransportModal() {
    if (this.spinner.showProgressBar.value) return;

    let dialogRef = this.dialog.open(
      CreateTransportModalComponent,
      {
        width: '600px'
      }
    );

    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.spinner.showProgressBar.next(true);

        let body: CreateTransportBody = {
          orderId: this.orderId!,
          name: resp,
        }

        this.orderService.createOrderTransport(body).subscribe({
          next: transportDetails => {
            this.orderFlowSyncService.order!.transportDetails = transportDetails;

            this.updateTransport();

            this.transportControl.setValue(this.transports[this.transports.length - 1].transportId);

            this.selectTransport(this.transports[this.transports.length - 1].transportId);

            this.spinner.showProgressBar.next(false);

            this.snackbar.positiveSentiment('Transport created');
          },
          error: err => {
            this.spinner.showProgressBar.next(false);

            this.snackbar.negativeSentiment(err.error);
          }
        })
      }
    })
  }

  openAddCarsToTransportModal() {
    if (this.spinner.showProgressBar.value) return;

    if (this.transports.length === 0) {
      this.snackbar.negativeSentiment('No transport order! Create one first');

      return;
    }

    let cars = this.carsNotInTransportTable?.dataSource.data.filter(d => d.isSelected);

    if (cars!.length === 0) {
      this.snackbar.negativeSentiment('No cars selected');

      return;
    }

    let dialogRef = this.dialog.open(
      AddCarToTransportModalComponent,
      {
        width: '800px',
        data: {
          cars: cars,
          transports: this.transportDropdown
        }
      }
    );

    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.spinner.showProgressBar.next(true);

        this.orderService.addCarsToTransport(this.orderId!, resp.transport, cars!.map(c => c.carMainInfoId)).subscribe({
          next: response => {
            this.orderFlowSyncService.order!.transportDetails = response;

            this.updateTransport();

            this.selectTransport(resp.transport);

            this.transportControl.setValue(resp.transport, { emitEvent: false });

            this.spinner.showProgressBar.next(false);

          },
          error: err => {
            this.spinner.showProgressBar.next(false);

            this.snackbar.negativeSentiment(err.error);
          }
        });
      }
    })
  }

  selectTransport(transportId: string) {
    this.selectedTransport = this.transports.find(t => t.transportId === transportId)!;

    this.transportDetailsForm.patchValue({
      companyName: this.selectedTransport.name,
      truckReg: this.selectedTransport.truckReg,
      load: this.selectedTransport.load,
      status: this.selectedTransport.status,
      pickupDate: this.selectedTransport.pickupDate,
      estimatedDeliveryDate: this.selectedTransport.estimatedDeliveryDate,
      actualDeliveryDate: this.selectedTransport.actualDeliveryDate,
    })

    this.carsInTransport = this.orderFlowSyncService.carList.filter(c => this.selectedTransport?.cars.find(tc => tc.carMainInfoId === c.carMainInfoId));

    this.carsInTransportTable?.updateTable(this.carsInTransport);

    this.pickupLocations = this.carsInTransport.reduce((arr: any, car) => {
      if (arr.some((l: any) => l.locationDetails.country === car.pickupDetails.locationId && l.locationDetails.city === car.pickupDetails.city && l.locationDetails.address === car.pickupDetails.address && l.locationDetails.postalCode === car.pickupDetails.postalCode)) {
        let index = arr.findIndex((l: any) => l.locationDetails.country === car.pickupDetails.locationId && l.locationDetails.city === car.pickupDetails.city && l.locationDetails.address === car.pickupDetails.address && l.locationDetails.postalCode === car.pickupDetails.postalCode);

        arr[index].cars.push({
          make: car.carConfig.make,
          model: car.carConfig.model,
          regNo: car.carConfig.regNo,
          cost: this.selectedTransport!.cars.find(c => c.carMainInfoId === car.carMainInfoId)!.transportationActualFee,
          carMainInfoId: car.carMainInfoId
        });
      } else {
        arr.push({
          cars: [{
            make: car.carConfig.make,
            model: car.carConfig.model,
            regNo: car.carConfig.regNo,
            cost: this.selectedTransport!.cars.find(c => c.carMainInfoId === car.carMainInfoId)!.transportationActualFee,
            carMainInfoId: car.carMainInfoId
          }],
          locationDetails: car.pickupDetails
        });
      }

      return arr;
    }, []);

    this.deliveryLocations = this.carsInTransport.reduce((arr: any, car) => {
      if (arr.some((l: any) => l.locationDetails.city === car.deliveryDetails.city && l.locationDetails.address === car.deliveryDetails.address && l.locationDetails.postalCode === car.deliveryDetails.postalCode)) {
        let index = arr.findIndex((l: any) => l.locationDetails.city === car.deliveryDetails.city && l.locationDetails.address === car.deliveryDetails.address && l.locationDetails.postalCode === car.deliveryDetails.postalCode);

        arr[index].cars.push({
          make: car.carConfig.make,
          model: car.carConfig.model,
          regNo: car.carConfig.regNo,
          carMainInfoId: car.carMainInfoId
        });
      } else {
        arr.push({
          cars: [{
            make: car.carConfig.make,
            model: car.carConfig.model,
            regNo: car.carConfig.regNo,
            carMainInfoId: car.carMainInfoId
          }],
          locationDetails: car.deliveryDetails
        });
      }

      return arr;
    }, []);

    if (this.selectedTransport?.documents.invoiceSentAt) {
      this.transportInvoiceSent = true;
      this.transportDetailsForm.controls.invoiceSentAt.setValue(this.selectedTransport.documents.invoiceSentAt);
      this.transportDetailsForm.controls.invoiceSentAt.enable();
    } else {
      this.transportInvoiceSent = false;
      this.transportDetailsForm.controls.invoiceSentAt.reset();
      this.transportDetailsForm.controls.invoiceSentAt.disable();
    }

    if (this.selectedTransport!.documents.CMRSentAt) {
      this.cmrSent = true;
      this.transportDetailsForm.controls.CMRSentAt.setValue(this.selectedTransport.documents.CMRSentAt);
      this.transportDetailsForm.controls.CMRSentAt.enable();
    } else {
      this.cmrSent = false;
      this.transportDetailsForm.controls.CMRSentAt.reset();
      this.transportDetailsForm.controls.CMRSentAt.disable();
    }

    this.invoicingDocuments = this.selectedTransport!.documents.invoice;
    this.cmrDocuments = this.selectedTransport!.documents.CMR;

    // this.estDeliveryDateControl.setValue(this.selectedTransport!.estimatedDeliveryDate);
    // this.transportStatus.setValue(this.selectedTransport!.status);
  }

  saveCost(car: TransportCar) {
    if (this.spinner.showProgressBar.value) return;

    if (isNaN(car.cost)) {
      this.snackbar.negativeSentiment('Insert numeric value');

      return;
    }

    this.spinner.showProgressBar.next(true);

    let body: UpdateCarTransportCostBody = {
      transportId: this.selectedTransport!.transportId,
      carMainInfoId: car.carMainInfoId,
      transportationActualFee: car.cost,
      orderId: this.orderId!
    }

    this.orderService.updateCarTransportCost(body).subscribe({
      next: resp => {
        this.orderFlowSyncService.loadProfits(resp);

        this.updateTransport();

        this.spinner.showProgressBar.next(false);
        this.snackbar.positiveSentiment('Cost saved');
      },
      error: err => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  removeCarFromTransport() {
    if (this.spinner.showProgressBar.value) return;

    let cars = this.carsInTransportTable!.dataSource.data.filter(d => d.isSelected).map(c => c.carMainInfoId);

    if (cars!.length === 0) {
      this.snackbar.negativeSentiment('No cars selected');

      return;
    }

    this.spinner.showProgressBar.next(true);

    this.orderService.removeCarsFromTransport(this.orderId!, this.selectedTransport!.transportId, cars).subscribe({
      next: val => {
        this.orderFlowSyncService.order!.transportDetails = val;

        this.updateTransport();

        this.selectTransport(this.selectedTransport!.transportId);

        this.spinner.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Cars removed from transport');
      },
      error: err => {
        this.spinner.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  removeTransportFromOrder() {
    if (this.spinner.showProgressBar.value) return;

    this.spinner.showProgressBar.next(true);

    this.orderService.removeTransportFromOrder(this.orderId!, this.selectedTransport!.transportId).subscribe({
      next: val => {
        this.orderFlowSyncService.order!.transportDetails = val;

        this.selectedTransport = undefined;

        this.updateTransport();

        this.transportControl.reset();

        this.spinner.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Transport removed from order');
      },
      error: err => {
        this.spinner.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  getTransportCost(): number {
    return this.selectedTransport!.cars.reduce((sum: number, car) => {
      return sum + car.transportationActualFee
    }, 0);
  }

  getCarsInTransport(): number {
    return this.transports.reduce((sum, t) => { return sum + t.cars.length }, 0)
  }

  updateTransport() {
    this.transports = this.orderFlowSyncService.order!.transportDetails.transports;
    this.orderTransportDetails = this.orderFlowSyncService.order!.transportDetails;

    this.transportDropdown = this.orderFlowSyncService.order!.transportDetails.transports.map(t => ({ viewValue: t.name, value: t.transportId }));

    this.carsNotInTransport = this.orderFlowSyncService.carList.filter(c => !this.transports.some(t => t.cars.find(tc => tc.carMainInfoId === c.carMainInfoId))).map(c => ({ ...c, isSelected: false }));

    if (this.carsNotInTransportTable) this.carsNotInTransportTable.updateTable(this.carsNotInTransport);

    if (this.carsInTransportTable) this.carsInTransportTable.data.forEach((d: { isSelected: boolean; }) => d.isSelected = false);
  }

  toggleDatePicker(type: string, event: boolean) {
    switch (type) {
      case 'cmr':
        if (event) {
          this.transportDetailsForm.controls.CMRSentAt.enable();
        } else {
          this.transportDetailsForm.controls.CMRSentAt.reset();
          this.transportDetailsForm.controls.CMRSentAt.disable();
        }
        break;
      case 'invoice':
        if (event) {
          this.transportDetailsForm.controls.invoiceSentAt.enable();
        } else {
          this.transportDetailsForm.controls.invoiceSentAt.reset();
          this.transportDetailsForm.controls.invoiceSentAt.disable();
        }
        break;
      default:
        break;
    }
  }

  checkTransportSeparate() {
    return this.orderFlowSyncService.order!.separateInvoiceForTransport;
  }

  openUpload(type: TransportDocumentsType) {
    this.uploadFileType = type;

    document.getElementById('uploadInput')?.click();
  }

  uploadFile(event: Event) {
    const target = event.target as HTMLInputElement;

    let file = target.files![0];

    this.utilsService.convertToBase64(file).then(fileBase64 => {
      let body: UploadTransportDocumentBody = {
        orderId: this.orderId!,
        transportId: this.selectedTransport!.transportId,
        doc: {
          name: file.name,
          data: fileBase64,
          type: this.uploadFileType,
        }
      };

      this.spinner.showProgressBar.next(true);

      this.orderService.uploadTransportDocument(body).subscribe({
        next: resp => {
          this.orderFlowSyncService.order!.transportDetails = resp;

          this.updateTransport();

          this.spinner.showProgressBar.next(false);

          this.snackbar.positiveSentiment('File uploaded');
        },
        error: err => {
          this.spinner.showProgressBar.next(false);

          this.snackbar.negativeSentiment(err.error);
        }
      })
    });
  }

  saveTransportDetails() {
    if (this.spinner.showProgressBar.value) return;

    if (this.transportDetailsForm.invalid) {
      this.snackbar.negativeSentiment('Insert company name');

      return;
    }

    if (this.transportDetailsForm.controls.status.value === this.transportStatusEnum.Delivered && !this.transportDetailsForm.controls.actualDeliveryDate.value) {
      this.snackbar.negativeSentiment('No actual delivery date set');

      return;
    }

    this.spinner.showProgressBar.next(true);

    let body = new UpdateTransportDetailsBody(this.orderId!, this.selectedTransport!.transportId, this.transportDetailsForm.value, this.utilsService);

    delete (body.utilsService);

    this.orderService.updateTransportDetails(body).subscribe({
      next: resp => {
        this.orderFlowSyncService.order!.transportDetails = resp;
        this.transports = resp.transports;

        this.spinner.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Transport status updated');
      },
      error: err => {
        this.spinner.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    });
  }
}
